<template>
  <div>
    <h1>Welcome to YellowDog</h1>

    <div class="blurb">
      <p>Thank you for subscribing to YellowDog Platform via the AWS Marketplace.</p>
      Please enter an account name you would like us to create. Please also enter your email address so we can send you
      details of how to access and start using YellowDog.
      <p>We will contact you within 48 hours with these details.</p>

      <div class="space-2">
        If you have already signed up go directly to the
        <el-link href="https://portal.yellowdog.co/" target="_blank" type="primary" class="bold"
          >YellowDog Portal</el-link
        >
      </div>
    </div>

    <el-form ref="form" :model="form" :rules="rules" label-position="top">
      <el-form-item label="Account Name ( organisation name )" prop="accountName">
        <el-input
          data-cy="input-accountName"
          v-model="form.accountName"
          placeholder="Enter an account name i.e your organisation's name"
          @keyup.enter="focusNext"
        />
      </el-form-item>
      <el-form-item label="Email" prop="email">
        <el-input data-cy="input-email" v-model="form.email" placeholder="Enter an email" @keyup.enter="focusNext" />
      </el-form-item>
      <el-form-item label="Confirm Email" prop="confirmEmail">
        <el-input
          data-cy="input-confirmEmail"
          v-model="form.confirmEmail"
          placeholder="Confirm email"
          @keyup.enter="onSubmit"
        />
      </el-form-item>

      <div class="form-actions">
        <el-button data-cy="btn-submit" type="primary" :loading="loading" @click="onSubmit">Sign Up</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { focusNext } from '@/core/helpers/input'
import axios from 'axios'

const baseURL = window.location.origin + '/api/'

/**
 * Create the Axios client
 */
const ApiClient = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

/**
 * Checks if account name is valid - be all lowercase, start with a letter and only contain letters, digits, hyphen or underscore
 * @param {Object} rule - the form item rule object ( unused )
 * @param {string} value - the input to be validated
 * @param {Function} callback -the callback function
 * @return {void} Nothing
 */
function validateAccountName(rule, value, callback) {
  const re = /^[a-z][a-z0-9_-]*?$/

  if (re.test(value)) callback()
  else
    callback(
      new Error(
        'An account name must be all lowercase, start with a letter and only contain letters, digits, hyphen or underscore'
      )
    )
}

export default {
  data() {
    return {
      loading: false,
      form: {
        accountName: '',
        email: '',
        confirmEmail: ''
      },

      rules: {
        accountName: [
          {
            required: true,
            message: 'An account name is required',
            trigger: 'blur'
          },
          {
            max: 50,
            message: 'Length should be no longer than 50',
            trigger: 'blur'
          },
          { validator: validateAccountName, trigger: 'blur' }
        ],
        email: [
          {
            required: true,
            message: 'A contact email is required',
            trigger: 'blur'
          },
          {
            type: 'email',
            message: 'Must be a valid email',
            trigger: 'blur'
          }
        ],
        confirmEmail: [
          {
            required: true,
            message: 'Please confirm email',
            trigger: 'blur'
          },
          { validator: this.validateConfirmEmail, trigger: 'blur' }
        ]
      }
    }
  },

  methods: {
    focusNext,

    validateConfirmEmail(rule, value, callback) {
      if (value !== this.form.email) {
        callback(new Error("The emails don't match!"))
      } else {
        callback()
      }
    },

    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          ApiClient.post(`awsAccountSetup/register`, {
            accountName: this.form.accountName,
            email: this.form.email
          })
            .then(() => {
              this.$emit('signup', { status: 'success' })
            })
            .catch(error => {
              this.$emit('signup', { status: 'error', error: error.response.data.message || error.message })
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>


<style scoped>
.blurb {
  margin: 3em 0;
  font-weight: 500;
}

.right-align {
  display: flex;
  justify-content: flex-end;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2em;
}
</style>
