<template>
  <div class="space-3">
    <h1>Success!</h1>

    <div class="space-3 bold">Account created successfully.</div>
    <div class="space-3">
      Your account will be activated within the next 48 hours and you will then be able to access the

      <el-link href="https://portal.yellowdog.co/" target="_blank" type="primary" class="bold"
        >YellowDog Portal</el-link
      >
    </div>

    <div class="space-3">
      If you need further support please contact
      <el-link href="mailto:support@yellowdog.co?subject=AWS Marketplace" target="_blank" type="primary" class="bold"
        >YellowDog</el-link
      >
    </div>
  </div>
</template>

<script>
export default {}
</script>


<style scoped>
</style>