<template>
  <div class="message">
    <h1>Sorry, there was an error</h1>
    <div class="message bold">{{ error }}</div>
    <div class="message">
      Please contact
      <el-link href="mailto:support@yellowdog.co?subject=AWS Marketplace" target="_blank" type="primary" class="bold"
        >YellowDog</el-link
      >
      for further support
    </div>

    <div class="form-actions">
      <el-button data-cy="btn-back" type="primary" @click="$emit('goBack')">Try Again</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: { type: String, required: true }
  }
}
</script>

<style scoped>
.message {
  margin: 3em 0;
}

.bold {
  font-weight: 600;
}
</style>