import { ElButton, ElLink } from 'element-plus'
import { ElForm, ElFormItem, ElInput,  } from 'element-plus'

export default app => {
  app.use(ElButton)
  app.use(ElForm)
  app.use(ElFormItem)
  app.use(ElInput)
   app.use(ElLink)
}
