<template>
  <div class="container">
    <a href="https://yellowdog.co/" target="_blank"><img src="./assets/logo.png" class="logo" /></a>
    <div class="content">
      <Success v-if="success" />
      <Error v-else-if="error" :error="error" @goBack="onReloadForm" />
      <SignUp v-else @signup="onSignUp" />
    </div>
  </div>
</template>

<script>
import SignUp from './components/SignUp.vue'
import Success from './components/Success.vue'
import Error from './components/Error.vue'

export default {
  name: 'App',

  components: {
    SignUp,
    Success,
    Error
  },

  data: () => ({ error: '', success: false }),

  methods: {
    onSignUp(response) {
      this.success = response.status === 'success'

      this.error = response.status === 'error' ? response.error || 'Unknown error occurred' : ''
    },

    onReloadForm() {
      this.success = false
      this.error = ''
    },

    onLogoClick() {
      window.open('https://yellowdog.co/')
    }
  }
}
</script>



<style>
.logo {
  margin-bottom: 2em;
  cursor: pointer;
}

.container {
  width: 30%;
  min-width: 480px;
  min-height: 850px;
  margin: 0 auto;
  background: #f8f9fa;
  border-radius: 3px;
}

.content {
  padding: 2em;
}

.space-3 {
  margin: 3em 0;
}

.space-2 {
  margin: 2em 0;
}

.bold {
  font-weight: 600;
}

/*element ui overrides*/
.el-input__inner,
.el-button {
  border-radius: 0px;
}

.el-button,
.el-button--primary,
.el-button--primary:active {
  border-color: #fab842;
  background-color: #fab842;
}

.el-button--primary:hover,
.el-button--primary:focus {
  border-color: #fbc668;
  background-color: #fbc668;
}

.el-form-item__label {
  display: block;
  width: 100%;
  padding-bottom: 1em;

  font-size: 14px;
  color: #606266;
  font-weight: 600;
  text-transform: uppercase;
}

.el-link.el-link--primary {
  color: #fab842;
  font-size: 16px;
}

html,
body {
  height: 100%;
  width: 100%;
  background: #f8f9fa;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  overflow-y: overlay;
  overflow-x: hidden;
  background: url('./assets/bg.jpg') center center no-repeat #08171d;
}

h1 {
  font-size: 2em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Mulish', sans-serif;
  margin-block-start: auto;
  margin-block-end: auto;
}

p {
  word-break: break-word;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
